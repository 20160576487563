.container
{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    gap: 10px;

    margin-top: 30px;
}


.requests_list
{
    display: flex;
    flex-wrap: wrap;

    gap: 10px;
}
