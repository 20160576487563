.container
{
    display: flex;
    flex-direction: column;

    width: 100%;

    gap: 50px;
}

.top_controls
{
    display: flex;
    justify-content: flex-end;
}

.types_list
{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.type_card
{
    display: flex;
    flex-direction: column;

    padding: 1rem;

    width: 265px;

    box-sizing: border-box;

    gap: 10px;

    border-radius: 8px;
    border: 2px solid var(--borderGrey);

    cursor: pointer;
}

.type_card:hover
{
    box-shadow: 0 0 16px rgba(41, 126, 38, 0.4);
    border-color: var(--btnColor);
}

.controls
{
    display: flex;
    justify-content: center;
    gap: 10px;
}


@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .types_list
    {
        justify-content: center;
    }
}
