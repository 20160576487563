.container, .container_device_company
{
    display: flex;
    flex-direction: column;

    width: 1000px;
    height: 80vh;
    max-height: 1000px;

    padding: 1rem;

    gap: 10px;

    position: relative;
}

.container_device_company
{
    width: 550px;
    height: 250px;
}

.container label, .container_device_company label
{
    display: flex;
    flex-direction: column;

    gap: 5px;
}

.specs_list
{
    display: flex;
    flex-direction: column;
    gap: 5px;

    height: 100%;
}

.list_top
{
    display: flex;
    justify-content: space-between;
}

.controls, .controls_device_company
{
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    position: fixed;

    background: #fff;
    bottom: 0;
    left: 0;

    width: 100%;

    padding: 1rem;
    box-sizing: border-box;
}

.controls_device_company
{
    justify-content: center;
}

.delete_btn
{
    all: unset;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 2px solid var(--error);
    border-radius: 8px;

    background: var(--error);

    cursor: pointer;
}

.delete_btn svg
{
    width: 25px;
    height: 20px;

    color: #fff;
}

.delete_btn:hover
{
    background: var(--errorHover);
    border-color: var(--errorHover);
}

.container table
{
    display: flex;
    flex-direction: column;

    text-align: center;
    width: 100%;
    height: calc(100% - 50px);

    overflow: hidden;
}

.container thead
{
    display: block;

    width: 100%;

    background: #a6a6a6;
}

.container thead tr
{
    display: flex;
}

.container thead th
{
    flex: 1;
}

.container table tbody
{
    display: flex;
    flex-direction: column;

    overflow-y: scroll;

    max-height: calc(100% - 190px);
    gap: 10px;

    padding: 10px;
}

.container tbody tr
{
    display: flex;
    align-items: center;

    border-radius: .5rem;
    box-shadow: 0 0 10px rgba(212,216,232,.6);

    padding: 5px;
}

.container tbody td
{
    flex: 1;
}


.container tbody td:first-child, .container thead th:first-child
{
    flex: 2;
}

.table_action
{
    display: flex;
    justify-content: center;
    gap: 5px;
}

.spec_name input
{
    display: flex;
    box-sizing: border-box;

    width: 100%;
}

.array_spec_container
{
    display: flex;


    margin-left: 1rem;
    margin-right: 1rem;
}

.array_spec_container td
{
    display: flex;

    flex: 1 !important;
}


.array_spec_container td input
{
    width: 80%;
}

.option_controls
{
    justify-content: flex-end;
}

.selectorShowHide button
{
    all: unset;
    text-align: center;

    width: 100%;
    border-radius: 8px;

    cursor: pointer;
}

.selectorShowHide button:hover
{
    background: var(--borderGrey);
}

.not_saved_message
{
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 1367px), (min-device-width: 768px) and (max-device-width: 1367px)
{
    .container, .container_device_company
    {
        width: 90vw;
    }
}

@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .container table
    {
        width: 100%;
        height: 100%;
    }

    .container thead
    {
        display: none;
    }

    .container tbody tr
    {
        display: flex;
        flex-direction: column;

        gap: 10px;

/*        width: 100%;*/

        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .container tbody td
    {
        width: 100%;
        text-align: left;
        font-size: .8em;
    }

    .container tbody td::before
    {
        content: attr(data-label);
        font-weight: bold;

        display: flex;
        gap: 5px;
    }

    .list_top
    {
        flex-direction: column;
        align-items: flex-start;

        gap: 10px;
    }

    .container table tbody
    {
        overflow-y: scroll;

        max-height: calc(100% - 170px);
    }

    .array_spec_container td input
    {
        width: 100%;
    }

    .option_controls
    {
        align-items: flex-start;
    }

}
