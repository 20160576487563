.container
{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1;

    overflow-y: scroll;

    gap: 10px;
}


.container table
{
    display: flex;
    flex-direction: column;

    text-align: center;
    width: 100%;
    height: 100%;

    overflow: hidden;

    flex: 1;
}

.container thead
{
    display: block;

    width: 100%;

    background: #a6a6a6;
}

.container thead tr
{
    display: flex;
}

.container thead th
{
    flex: 1;
}

.container tbody
{
    display: flex;
    flex-direction: column;

    overflow-y: scroll;

    max-height: 750px;
    gap: 10px;

    padding: 10px;
}

.container tbody tr
{
    display: flex;
    align-items: center;

    border-radius: .5rem;
    box-shadow: 0 0 10px rgba(212,216,232,.6);

    padding: 5px;
}

.container tbody td
{
    flex: 1;
}

.action_controls
{
    display: flex;
    justify-content: center;

    gap: 10px;
}

.message
{
    text-align: left;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    width: 100%;

    position: relative;
}

.read_more:hover:before
{
    content: "";

    width: 100%;

    cursor: pointer;
}

.read_more:hover:before
{
    content: "Read more";

    text-align: center;

    position: absolute;

    width: 100%;

    background: rgba(209, 209, 209, 0.95);

    border-radius: 8px;

    font-weight: 600;
}


.read_more_container
{
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    gap: 5px;

    padding: 1rem;
}

.read_more_container span
{
    max-height: 300px;
    overflow-y: scroll;
}

.controls
{
    display: flex;
    justify-content: flex-end;
}

.loader
{
    position: absolute;

    top: 50%;
    left: 25%;
    right: 25%;
}

@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .container, .container table, .container tbody
    {
        height: 100%;
    }

    .container thead
    {
        display: none;
    }

    .container tbody tr
    {
        display: flex;
        flex-direction: column;

        gap: 10px;

        width: 100%;

        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .container tbody td
    {
        width: 100%;
        text-align: left;
        font-size: .8em;
    }

    .container tbody td::before
    {
        content: attr(data-label);
        font-weight: bold;
    }

    .container tbody
    {
        overflow: initial;
        max-height: 100%;

        padding-bottom: 80px;
    }

}
