.container
{
    display: flex;
    flex-direction: column;


    width: 100%;

    gap: 50px;
}

.devices_list_content
{
    display: flex;
    flex-direction: column;
    overflow: unset !important;

    padding-bottom: 10px;

    width: 100%;
}

.devices_list
{
    display: flex;
    flex-wrap: wrap;

    width: 100%;

    gap: 10px;
}

.top_controls
{
    display: flex;
    justify-content: space-between;

    gap: 10px;
}

.search_container
{
    width: 254px;
}

@media only screen and (max-width: 609px), (min-device-width: 375px) and (max-device-width: 609px)
{
    .devices_list
    {
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
    }

    .top_controls
    {
        flex-direction: column;
        align-items: flex-start;
    }
}
