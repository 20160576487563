.container
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 70px);
    width: 100%;

    gap: 10px;
}

.container h1, h3
{
    text-align: center;
    padding: 0;
    margin: 0;
}

.container label
{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
