.container
{
    display: flex;
    width: 100%;
    gap: 24px;
}

.devices_container, .filters_container
{
    padding-top: 30px;
}

.devices_container
{
    display: flex;
    flex-direction: column;

    width: calc(100% - 314px);

    gap: 20px;
}

.filters_container
{
    display: flex;
    width: 218px;
    min-height: 80vh;
    flex-direction: column;
    gap: 32px;
    padding: 30px 24px;
    background: #F5F5F5;
}

.header
{
    font-weight: 600;

    font-size: 30px;
}

.devices_list
{
    display: flex;
    flex-wrap: wrap;

    width: 100%;

    gap: 10px;
}

.filters_container label
{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.selected_type_container
{
    display: flex;
    flex-direction: column;

    gap: 10px;
}

.top
{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.sort_search
{
    display: flex;
    align-items: flex-end;
    gap: 10px;
}

.search
{
    display: flex;
    width: 254px;
}

.show_filters
{
    all: unset;

    display: none;
    align-items: center;
    border: 2px solid var(--borderGrey);
    border-radius: 8px;

    padding: 5px;

    cursor: pointer;
}

.show_filters:hover
{
    border-color: var(--btnColor);
}

.show_filters svg
{
    width: 16px;
    height: 16px;
}

.filters_close
{
    display: none;
}

.filters_main_container_show
{
    display: flex;
}

.filters_main_container_hide
{
    display: none;
}

.search_filters
{
    display: flex;

    gap: 10px;
}

.devices_list_content
{
    display: flex;
    flex-direction: column;
    overflow: unset !important;

    padding-bottom: 10px;

    width: 100%;
}

.loader
{
    display: flex;
    justify-content: center;
    padding: 0.625rem;
}

@media only screen and (max-width: 1366px), (min-device-width: 768px) and (max-device-width: 1366px)
{
    .show_filters, .filters_close
    {
        display: flex;
    }

    .container
    {
        flex-direction: column;
    }

    .devices_container, .filters_container
    {
        width: 100%;
    }

    .filters_main_container
    {
        justify-content: flex-end;

        position: absolute;

        width: 100%;
        height: 100%;

        left: 0;

        z-index: 3;

        background: rgba(0,0,0,.5);
    }

    .filters_container
    {
        display: flex;
        right: -1000px;
        padding: 30px 10px 10px 10px;
        align-items: center;

        position: relative;

        width: 300px;
        height: 80vh;

        overflow-y: scroll;

        animation: .5s showFilters ease-out forwards;
    }

    @keyframes showFilters {
        from
        {
            right: -1000px;
        }
        to
        {
            right: 0;
        }
    }

    .filters_controls
    {
        display: flex;
        gap: 10px;
    }

    .selected_type_container
    {
        width: 100%;

        padding-bottom: 50px;
    }
}

@media only screen and (max-width: 999px), (min-device-width: 768px) and (max-device-width: 999px)
{
    .top
    {
        align-items: flex-start;
        flex-direction: column;

        gap: 10px;
    }

    .sort_search
    {
        flex-direction: column;
        align-items: flex-start;
    }

    .search
    {
       width: 70%;
    }

    .show_filters
    {
        width: fit-content;
    }
}
