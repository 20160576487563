.container
{
    display: flex;
    flex-direction: column;

    width: 700px;
    max-height: 560px;

    height: 80vh;

    gap: 1rem;

    padding: 1rem;

    overflow-y: scroll;
}

.container h1
{
    text-align: center;

    padding: 0;
    margin: 0;

    color: var(--btnColor)
}

.info_container, .descriptions
{
    display: flex;
    flex-direction: column;

    gap: 1rem;
}

.controls label
{
    display: flex;
    align-items: center;
    gap: 5px;
}

.controls label input
{
    width: 25px;
    height: 25px;
}

.controls
{
    display: flex;
    justify-content: space-between;
}

.state
{

    width: fit-content;

    border-radius: 8px;
    padding: 2px 5px;

    color: #fff;

    font-weight: 600;
}

.available
{
    background: var(--success);
}

.free
{
    background: var(--successBlue);
}

.descriptions p
{
    line-height: 150%;
    padding: 0;
    margin: 0;
}

.warning
{
    font-weight: 600;
}

@media only screen and (max-width: 750px), (min-device-width: 768px) and (max-device-width: 750px)
{
    .container
    {
        width: 90vw;
    }

    .controls
    {
        align-items: flex-end;
        flex-direction: column;
        gap: 10px;
    }
}
