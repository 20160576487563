.container
{
    display: flex;
    flex-direction: column;

    width: 1100px;
    height: 80vh;
    max-height: 1050px;

    position: relative;
}


.render_switch_container
{
    height: calc(100% - 147.2px);

    padding: 73px 1rem 0 1rem;

}

.controls, .render_controls
{
    display: flex;
    justify-content: center;
    gap: 10px;

    position: fixed;

    background: #fff;

    padding: 1rem;

    box-sizing: border-box;

    width: 100%;

    z-index: 4;
}

.controls
{
}

.render_controls
{
    justify-content: flex-end;
    align-items: flex-end;

    bottom: 0;
    right: 0;
}

.saving_state
{
    display: flex;
    gap: 5px;

    align-items: center;
}

.not_saved_message
{
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 1367px), (min-device-width: 768px) and (max-device-width: 1367px)
{
    .container
    {
        width: 90vw;
    }
}

@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .container
    {
        overflow-y: scroll;
    }
    .render_switch_container
    {
        height: fit-content;
        padding-bottom: 73px;
    }
}

@media only screen and (max-width: 750px), (min-device-width: 768px) and (max-device-width: 750px)
{
    .container
    {
        height: 80vh;
    }
}
