.container, .container_control_panel
{
    display: flex;
    flex-direction: column;

    border: 2px solid var(--borderGrey);
    border-radius: 8px;

    height: 450px;

    cursor: pointer;

    position: relative;
    box-sizing: border-box;
}

.container
{
    width: 255.5px;
    flex: 0 0 255.5px;
}

.container_control_panel
{
    width: 334px;
    flex: 0 0 334px;
}

.container:hover, .container_control_panel:hover
{
    box-shadow: 0 0 16px rgba(41, 126, 38, 0.4);
    border-color: var(--btnColor);
}

.company_tag
{
    display: flex;
    width: fit-content;

    font-weight: 600;

    position: absolute;

    z-index: 2;

    border: 2px solid;
    padding: 2px 5px 2px 5px;
    border-radius: 8px;
    right: -10px;
    top: -15px;

    color: #fff;
}

.company_tag svg
{
    width: 50px;
    height: 25px;
}

.company_default
{
    background: var(--navbarColor);
    border-color: var(--navbarColor);
}

.company_default svg
{
    color: #fff;
    fill: #fff;
}

.company_buutti
{
    background: #fff;
    border-color: #000;
}

.images_swiper, .image_placeholder
{
    display: flex;

    width: 100%;
    height: 40%;

    background: #fff;

    border-radius: 6px 6px 0 0;
}

.container_control_panel > .images_swiper, .container_control_panel > .image_placeholder, .container_control_panel > .content
{
    height: 50%;
}

.image_placeholder
{
    justify-content: center;
    align-items: center;
}

.image_placeholder svg
{
    width: 60px;
    height: 60px;
}

.image_slide
{
    width: 100%;
    height: 100%;

    background: #fff;
}

.image_slide img
{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content
{
    display: flex;
    flex-direction: column;

    gap: .5rem;

    height: 60%;

    padding: 1rem 1rem 0 1rem;
}

.general_info
{
    display: flex;
    flex-direction: column;

    gap: 0.5rem;

    flex: 1;
}

.top
{
    display: flex;
    flex-direction: column;
}

.type, .toriID
{
    font-size: 13px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7)
}

.header
{
    font-weight: 600;
    font-size: 24px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.info_content, .info_content_default
{
    display: flex;
    flex-direction: column;
    gap: 5px;

    line-height: 150%;
}

.info_content_default
{
    height: initial;
    flex: 1;
}

.description
{
    width: 100%;
/*    height: 60px;*/

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.controls
{
    display: flex;
    justify-content: center;
    height: 40px;
    gap: 10px;

    padding: 1rem;
}

.description_container
{
    height: 50px;
    overflow: hidden;
}

.no_description
{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal !important;
    font-style: italic;

    height: 50px;
}

.leasingEndDate
{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    word-break: break-all;
}

.sold
{
    background: linear-gradient(180deg, var(--error) 25%, rgba(218, 241, 217, 0) 70%);
}

.reserved
{
    background: linear-gradient(180deg, var(--warning) 25%, rgba(218, 241, 217, 0) 70%);
}

.available
{
    background: linear-gradient(180deg, var(--success) 25%, rgba(218, 241, 217, 0) 70%);
}

.free
{
    background: linear-gradient(180deg, var(--successBlue) 25%, rgba(218, 241, 217, 0) 70%);
}

.card_link_container
{
    all: unset;
}

@media only screen and (max-width: 1366px), (min-device-width: 768px) and (max-device-width: 1366px)
{
    .container, .container_control_panel
    {
        width: calc(25% - 7.5px);
        flex: 0 0 calc(25% - 7.5px);
    }

    .container
    {
        height: 500px;
    }

    .images_swiper, .content, .image_placeholder
    {
        height: 50%;
    }
}

@media only screen and (max-width: 1199px), (min-device-width: 768px) and (max-device-width: 1199px)
{
     .container_control_panel, .container
    {
         width: calc(32.9% - 3px);
         flex: 0 0 calc(32.9% - 3px);

         height: 500px;
    }
}

@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .container, .container_control_panel
    {
        width: calc(34% - 13.2px);
        flex: 0 0 calc(34% - 13.2px);
    }

    .content
    {
        height: 50%;
    }

    .images_swiper, .image_placeholder
    {
        height: 60%;
    }
}

@media only screen and (max-width: 899px), (min-device-width: 768px) and (max-device-width: 899px)
{
    .container, .container_control_panel
    {
        width: calc(50% - 5px);
        flex: 0 0 calc(50% - 5px);

        height: 600px;
    }

    .container_control_panel
    {
        height: 500px;
    }

}

@media only screen and (max-width: 609px), (min-device-width: 375px) and (max-device-width: 609px)
{
    .container_control_panel
    {
        width: 100%;
        flex: initial;

        height: 450px;
    }

    .container_control_panel > .images_swiper, .container_control_panel > .content, .container_control_panel > .image_placeholder
    {
        height: 50%;
    }

    .container
    {
        height: 420px;
    }

    .content
    {
        height: 70%;
    }

    .images_swiper, .image_placeholder
    {
        height: 30%;
    }

    .leasingEndDate
    {
        white-space: pre-wrap;
        -webkit-line-clamp: 3;
    }
}
