:root
{
    --background: rgb(22, 22, 26);
    --navbarColor: #3AA935;
    --btnColor: #297E26;
    --filterLabelColor: #297625;
    --btnColorDisabled: #cccccc;

    --error: #C6394A;
    --warning: #E6BD19;
    --success: #1EA966;
    --successBlue: #1EA7A9;

    --errorHover: #8a2733;

   --borderGrey: #D1D1D1;

}
