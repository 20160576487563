.main_container
{
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    gap: 10px;

    width: 100%;
    height: 100%;

    flex: 1;
}

.container
{
    display: flex;
    width: 100%;
    height: 100%;

    gap: 20px;
}

.general_container, .specs_container
{
    display: flex;
    flex-direction: column;

    width: 50%;

    gap: 5px;

    overflow: scroll;
}

.general_container
{
    padding-right: 10px;
}

.general_container label
{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.general_container textarea
{
    height: 200px;

    resize: none;
}

.specs_list
{
    display: flex;
    flex-wrap: wrap;

    gap: 5px;

    overflow-y: scroll;
}

.spec
{
    display: flex;
    flex-direction: column;
    gap: 5px;

    flex: 0 0 calc(50% - 5px);
}

.spec select
{
    height: 100%;
    border-radius: 8px;
}

.dropbox_container
{
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    text-align: center;

    border: 3px dashed var(--borderGrey);

    border-radius: 8px;

    height: 70px;
}

.images_preview
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    gap: 5px;
}

.image_card
{
    display: flex;
    position: relative;

    width: 160px;
    height: 110px;

    border-radius: 10px;
    overflow: hidden;

    border: 2px solid var(--borderGrey);
}

.image_card img
{
    width: 100%;
    height: 100%;

    position: relative;

    object-fit: contain;
}

.delete_image
{
    all: unset;
    display: flex;
    justify-content: center;

    right: 0;

    position: absolute;


    z-index: 2;
    cursor: pointer;

    color: #fff;

    border: 2px solid var(--error);
    background: var(--error);

    border-radius: 5px;

    padding: 5px;
}

.delete_image svg
{
    width: 15px;
    height: 15px;
}

.delete_image:hover
{
    border-color: var(--errorHover);
    background: var(--errorHover);
}

.date_picker
{
    font-size: 1rem;

    border-radius: 5px;
    padding: 8px;

    border: 2px solid var(--borderGrey);
    cursor: pointer;

    outline: none;

    width: 50%;
}

.date_picker:focus
{
    border: 2px solid var(--btnColor);
}

.add_new_type
{
    display: flex;
}

.main_container select option:disabled
{
    background: transparent;
}


@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .loader
    {
        position: absolute;

        top: 50%;
    }

    .main_container
    {
        height: fit-content;
    }

    .general_container, .specs_container
    {
        width: 100%;

        overflow: unset;
    }

    .container
    {
        flex-direction: column;
        height: fit-content;
    }

    .spec
    {
        width: 100%;
        flex: initial;
    }

    .dropbox_container
    {
        font-size: 16px;
    }

    .image_card
    {
        width: calc(25% - 1px);
        flex: 0 0 calc(25% - 1px);
        min-height: 120px;
        height: auto;
    }
}

@media only screen and (max-width: 750px), (min-device-width: 375px) and (max-device-width: 750px)
{
    .image_card
    {
        width: calc(50% - 3px);
        flex: 0 0 calc(50% - 3px);
    }
}
