.container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    gap: 50px;

    margin-top: 30px;
}

.controls
{
    display: flex;
    gap: 10px;
}

@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .controls
    {
        flex-wrap: wrap;
        justify-content: center;
    }
}
