@import 'helpers/Colors.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code
{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root
{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-container
{
  width: 1366px;
}

select
{

  border-radius: 5px;
  background: transparent;
  padding: 7px 12px;

  border: 2px solid var(--borderGrey);

  cursor: pointer;
}

select:focus, select:hover
{
  border-color: var(--btnColor);
}

input, textarea
{
  border-radius: 10px;
  padding: 0.625rem;
  border: 2px solid var(--borderGrey);
  outline: none;
}

input:focus, textarea:focus
{
  border-color: var(--btnColor);
}

.react-datepicker-wrapper
{
  display: block !important;
}

.thumbsSwiper .swiper-slide
{
  opacity: 0.4 !important;
}

.thumbsSwiper .swiper-slide-thumb-active
{
  opacity: 1 !important;
}

.image_slide_active .swiper-slide-thumb-active
{
  border-color: var(--btnColor);
}

.react-datepicker-popper
{
  z-index: 3 !important;
}

@media only screen and (max-width: 1366px), (min-device-width: 768px) and (max-device-width: 1366px)
{
  .main-container
  {
    width: 100vw;

    box-sizing: border-box;
    padding: 0 10px 10px 10px;
  }
}

@media only screen and (max-width: 750px), (min-device-width: 768px) and (max-device-width: 750px)
{
  input, textarea
  {
    font-size: 6vw;
  }
}
