.container, .container_control_panel
{
    width: 334px;
    flex: 0 0 334px;

    border: 2px solid var(--borderGrey);
    border-radius: 8px;

    box-sizing: border-box;
}

.container
{
    height: 375px;
}

.container_control_panel
{
    height: 400px;
}

.card
{
    display: flex;
    flex-direction: column;

    padding: 1rem;

    width: 100%;
    height: 100%;

    gap: 20px;

    box-sizing: border-box;
    cursor: pointer;
}

.device_header
{
    font-weight: 600;
    font-size: 28px;

    width: 100%;
}

.device_header a
{
    display: flex;
    align-items: center;
    gap: 10px;

    color: #000;

    text-decoration: none;
}

.device_header a span
{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.device_header:hover, .device_header a:hover
{
    color: var(--filterLabelColor)
}

.general_info, .general_info_view_more
{
    display: flex;
    flex-direction: column;

    gap: 10px;
}

.general_info
{
    height: 100%;
}

.general_info_view_more
{
    height: 50vh;
    max-height: 650px;

    overflow-y: scroll;
}

.general_info label, .general_info_view_more label
{
    display: flex;
    gap: 10px;
}

.message_container
{
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 82px;
}

.message_container p
{
    padding: 0;
    margin: 0;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.rejected
{
    background: linear-gradient(180deg, var(--error) 25%, rgba(218, 241, 217, 0) 70%);
}

.pending
{
    background: linear-gradient(180deg, var(--warning) 25%, rgba(218, 241, 217, 0) 70%);
}

.accepted
{
    background: linear-gradient(180deg, var(--success) 25%, rgba(218, 241, 217, 0) 70%);
}

.view_more_state
{

    width: fit-content;

    border-radius: 8px;
    padding: 5px;

    color: #fff;

    font-weight: 600;
}

.view_more_rejected
{
    background: var(--error);
}

.view_more_pending
{
    background: var(--warning);
}

.view_more_accepted
{
    background: var(--success);
}

.view_more_container
{
    display: flex;
    flex-direction: column;

    width: 800px;
    height: fit-content;

    padding: 1rem;

    gap: 10px;
}

.view_more_messages
{
    display: flex;
    flex-direction: column;

    gap: 5px;

    width: 100%;
}

.view_more_messages span
{
    font-weight: 600;
}

.view_more_messages p
{
    padding: 0;
    margin: 0;


    word-break: break-word;
}

.manage_textarea
{
   height: 250px;

    resize: none;

    font-size: 16px;
}

.controls_main, .controls
{
    display: flex;
    gap: 10px;
}

.controls_main
{
    justify-content: center;
}

.controls
{
    justify-content: flex-end;
}


.no_message
{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal !important;
    font-style: italic;

    height: 100%;
}

.info_item, .message_container span
{
    font-weight: 600;
}

@media only screen and (max-width: 1366px), (min-device-width: 768px) and (max-device-width: 1366px)
{
    .container, .container_control_panel
    {
        width: calc(25% - 7.5px);
        flex: 0 0 calc(25% - 7.5px);
    }
}

@media only screen and (max-width: 1200px), (min-device-width: 768px) and (max-device-width: 1200px)
{
    .container, .container_control_panel
    {
        width: calc(33% - 7px);
        flex: 0 0 calc(33% - 7px)
    }

    .view_more_container
    {
        width: 90vw;
        height: 80vh;
    }

    .general_info_view_more
    {
        height: 100%;
    }
}

@media only screen and (max-width: 876px), (min-device-width: 768px) and (max-device-width: 876px)
{
    .container, .container_control_panel
    {
        flex: 300px;
        width: 300px;
    }
}
