.main_container
{
    display: flex;
    flex-direction: column;

    width: 100%;

    margin-top: 30px;
}

.device_container
{
    display: flex;
    flex-direction: column;

    width: 100%;

    gap: 20px;
}

.top_container
{
    display: flex;
    gap: 20px;
}

.swipers_container, .image_placeholder
{
    display: flex;
    flex-direction: column;
    width: 45%;

    gap: 5px;
}

.image_placeholder
{
    height: 400px;

    justify-content: center;
    align-items: center;
}

.image_placeholder svg
{
    width: 150px;
    height: 150px;
}

.images_swiper
{
    border-radius: 8px;

    width: 100%;
    height: 400px;

    border: 2px solid var(--borderGrey);
}

.image_slide
{
    width: 100%;
    height: 100%;
}

.transformWrapper
{
    width: 100% !important;
    height: 100% !important;
}

.transformContent
{
    width: 100% !important;
    height: 100% !important;
}

.transformContent img
{
    width: 100%;
    height: 100%;

    object-fit: contain;
    background: #fff;
    scale: 1.1;
}


.thumbs_swiper
{
    width: 100%;
    height: fit-content;
}

.image_slide_thumbs
{
    width: 100px;
    height: 100px;

    border: 2px solid var(--borderGrey);
    border-radius: 8px;

    overflow: hidden;
}

.image_slide_thumbs img
{
    width: 100%;
    height: 100%;

    object-fit: cover;
}

.general_info
{
    display: flex;
    flex-direction: column;

    width: 55%;

    gap: 20px;
}

.top
{
    display: flex;
    flex-direction: column;
}

.header
{
    font-size: 36px;
    font-weight: 600;

    overflow: hidden;
    text-overflow: ellipsis;
}

.type
{
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7)
}

.info
{
    display: flex;
    flex-direction: column;

    gap: 0.5rem
}

.controls
{
    display: flex;
}


.specs_list
{
    display: flex;
    flex-direction: column;

    gap: 5px;
}

.spec
{

    width: fit-content;
    padding: 0.5rem;

    border: 2px solid var(--borderGrey);
    border-radius: 8px;

    font-style: italic;
}

.spec_label
{
    font-weight: 600;
    font-style: normal;

    font-size: 20px;
}

.loading_not_found_container
{
    display: flex;

    justify-content: center;

    height: 85vh;
}

.no_description
{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal !important;
    font-style: italic;

    height: 100%;
}

.description
{
    word-break: break-word;
}

.span_text span
{
    font-weight: 600;
}

.state
{

    width: fit-content;

    border-radius: 8px;
    padding: 2px 5px;

    color: #fff;

    font-weight: 600;
}

.sold
{
    background: var(--error);
}

.reserved
{
    background: var(--warning);
}

.available
{
    background: var(--success);
}

.free
{
    background: var(--successBlue);
}

.company_container
{
    display: flex;
    align-items: center;
    gap: 5px;
}

.company_tag
{
    display: flex;
    width: fit-content;

    font-weight: 600;

    border: 2px solid;
    padding: 2px 5px 2px 5px;
    border-radius: 8px;

    color: #fff;
}

.company_tag svg
{
    width: 50px;
    height: 25px;
}

.company_default
{
    background: var(--navbarColor);
    border-color: var(--navbarColor);
}

.company_default svg
{
    color: #fff;
    fill: #fff;
}

.company_buutti
{
    background: #fff;
    border-color: #000;
}

@media only screen and (max-width: 1000px), (min-device-width: 768px) and (max-device-width: 1000px)
{
    .top_container
    {
        flex-direction: column;
    }

    .swipers_container, .general_info
    {
        width: 100%;
    }

    .image_slide
    {
        display: flex;
        justify-content: center;
    }

    .image_slide img
    {
        border-radius: 8px;
    }

    .image_placeholder
    {
        width: 100%;
    }

    .image_slide img, .thumbs_swiper
    {
        width: 500px;
    }

}

@media only screen and (max-width: 500px), (min-device-width: 375px) and (max-device-width: 500px)
{
    .images_swiper
    {
        height: 300px;
    }

    .image_slide
    {
        display: unset;
    }

    .image_slide img
    {
        width: 100%;
        border-radius: unset;
    }

    .thumbs_swiper
    {
        width: 100%;
    }

    .image_slide_thumbs
    {
        height: 65px;
    }
}

