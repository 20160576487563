.container
{
    display: flex;
    flex-direction: column;
    gap: 5px;

    width: 100%;

    position: relative;

}

.selector
{
    width: 100%;

    border-radius: 5px;
    background: transparent;
    padding: 7px 12px;

    border: 2px solid var(--borderGrey);

    cursor: pointer;
    height: 32px;

    box-sizing: border-box;
}

.selector:hover, .show_options
{
    border-color: var(--btnColor);
}

.show_options
{
    border-radius: 5px 5px 0 0;

    border-bottom: none;
}

.selector_face
{
    display: flex;

    justify-content: space-between;

    width: 100%;
}

.selector_face button
{
    all: unset;

    display: flex;
    align-items: center;
    height: 100%;

    border: 2px solid transparent;
}

.selector_face button:hover
{
    border: 2px solid var(--btnColor);
    border-radius: 5px;
}

.selector_face button:active
{
    background: rgba(58, 169, 53, 0.10);
}

.selector_face input
{
    all: unset;

    width: 100%;
}

.options_container_main
{
    display: flex;
    position: absolute;

    width: 100%;

    left: 0;
    right: 0;

    box-sizing: border-box;

    padding-bottom: 50px;

    z-index: 3;
}


.options_container
{
    display: flex;

    width: 100%;

    flex-direction: column;

    background: #F5F5F5;

    border: 2px solid var(--btnColor);

    gap: 5px;

    border-radius: 0 0 5px 5px;

    border-top: none;

    overflow: hidden;

    max-height: 300px;
    overflow-y: scroll;

    padding-top: 10px;
}


.option
{
    display: flex;
    align-items: center;
    gap: 1rem;

    hyphens: auto;
    padding: 0.5rem 1rem;

}

.option:hover
{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #3AA935;
}

.chosen
{
    color: #757575;
}

.selected_options_container
{
    display: flex;
    flex-wrap: wrap;

    max-height: 60px;
    overflow-y: scroll;

    gap: 5px;
}

.selected_option
{
    display: flex;
    align-items: center;
    border-radius: 2rem;
    border: 1px solid var(--btnColor);

    padding: 0.25rem 0.5rem;

    gap: 8px;

    font-weight: 400;
    font-size: 12px;
    box-sizing: border-box;

    width: fit-content;

    cursor: pointer;
}

.selected_option span
{
    color: #000;
}

.selected_option svg
{
    color: var(--btnColor);
    width: 14px;
    height: 14px;
}

.selected_option:hover, .selected_option:active, .selected
{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #3AA935;
}

.delete_selected
{
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.label
{
    font-weight: bold;
    color: var(--filterLabelColor);

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.disabled
{
    background: var(--btnColorDisabled);
    border-color: var(--btnColorDisabled);

    cursor: default;

    pointer-events: none;
}
