.container
{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;

    width: 100%;
}

.requests_list
{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
