.container
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 10px;
}

.container label
{
    display: flex;
    flex-direction: column;
    gap: 5px;

    font-weight: 600;
}
