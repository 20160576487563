.container, .container label, .request_container
{
    display: flex;
    flex-direction: column;
}

.header
{
    text-align: center;
    font-weight: 600;
    font-size: 34px;

    white-space: pre-wrap;
    word-break: break-word;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.container
{
    gap: 10px;
    padding: 1rem;
    width: 700px;
}

.request_container
{
    flex: 1;
}

.request_container label
{
    gap: 5px;
}

.request_container
{
    gap: 10px;
}

.request_container label textarea
{
    resize: none;
    height: 250px;
}


.controls
{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.leasingEndDate
{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

}

@media only screen and (max-width: 750px), (min-device-width: 768px) and (max-device-width: 750px)
{
    .container
    {
        width: 90vw;
        height: 80vh;

        overflow-y: scroll;
    }

    .header
    {
        font-size: 7vw;

        display: initial;
        overflow: visible;
    }
}
